<template>
  <v-container class="container--fluid grid-list-md">
    <v-row no-gutters>
      <v-col cols="12">
        <v-card
          class="mx-auto"
          outlined
        >
          <ListSkeleton v-if="listSkeleton"/>
          <v-card-text class="text-h5 pa-0" v-else>
            <v-data-table
              :height="salesReportList.data && salesReportList.data.length > 0 ? '400' : ''"
              :style="{maxHeight: !salesReportList.data ? '440px' : ''}"
              :dense="true"
              class="custom-table mb-0 ma-2 row-pointer"
              :headers="!isFocused ? headers : focusedHeaders"
              :items="!isFocused ? !withPrice ? withPriceinvoiceData : invoiceData : salesReportList.data"
              item-class="py-4"
              fixed-header
              :search="search"
              :loading="isProgressCircular"
              @pagination="newPagination"
              @page-count="pageCount = $event"
              :items-per-page="limitData"
              :page.sync="page"
              @dblclick:row="doubleClick"
            >
              <template v-slot:loading>
                <v-overlay>
                  <v-icon size="64" class="hourglass-icon" color="primary">mdi-timer-sand-paused</v-icon>
                </v-overlay>
              </template>
              <template
                v-slot:top
              >
                <v-card-title class="py-1 primary--text">
                  <v-icon color="primary">mdi-filter-cog-outline</v-icon>
                  <h6 class="ms-2 font-weight-regular">{{$t('salesReport.filters')}}</h6>
                  <v-spacer />
                  <HtmlToPdf class="me-2 mb-1" :disabled="!!(salesReportList.data && salesReportList.data.length > 0)" :model="salesReportList" :isFocused="isFocused" :withPrice="withPrice" :payload="payload" type="SalesReportPDF"/>
                  <ExcelAndResetFilterCommon :data="(salesReportList.data && salesReportList.data.length > 0)" @downloadExcelFile="downloadExcelFile" @resetFilters="resetFilters"/>
                </v-card-title>
                <v-divider />
                <v-form v-model="valid" ref="form">
                <v-card-text>
                  <v-row>
                    <v-col cols="5">
                      <v-row :dense="true">
                        <v-col cols="6" class="d-flex">
                          <v-menu
                            ref="invoiceStartDateMenu"
                            v-model="invoiceStartDateMenu"
                            :close-on-content-click="false"
                            :return-value.sync="invoice_start_date"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                hide-details="auto"
                                dense
                                class="me-2"
                                v-model="formattedFromDate"
                                :label="$t('salesReport.fromInvoiceDate')"
                                @input="buildUrl"
                                readonly
                                v-bind="attrs"
                                :rules="profileById.date_format ? dateValid : dateValid2"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="invoice_start_date"
                              @input="buildUrl"
                              @change="$refs.invoiceStartDateMenu.save(invoice_start_date)"
                            >
                              <v-btn small class="primary" @click="$refs.invoiceStartDateMenu.save(todayDate())">{{ $t('route.today') }}</v-btn>
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="6" class="d-flex">
                          <v-menu
                            ref="invoiceEndDateMenu"
                            v-model="invoiceEndDateMenu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                hide-details="auto"
                                dense
                                v-model="formattedToDate"
                                :label="$t('salesReport.toInvoiceDate')"
                                @input="buildUrl"
                                readonly
                                :rules="profileById.date_format ? dateValid : dateValid2"
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="model.date_printed"
                              @input="buildUrl"
                              @change="$refs.invoiceEndDateMenu.save(model.date_printed)"
                            >
                              <v-btn small class="primary" @click="$refs.invoiceEndDateMenu.save(getNow())">{{ $t('route.today') }}</v-btn>
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="1">
                    </v-col>
                    <v-col cols="6">
                      <v-row :dense="true">
                        <v-col cols="6">
                          <v-autocomplete
                            autocomplete="new-password"
                            dense
                            cache-items
                            v-model="selectedCustomer"
                            :items="customerList.data"
                            item-text="name_1"
                            item-value="id"
                            flat
                            return-object
                            hide-no-data
                            hide-details="auto"
                            single-line
                            clearable
                            @change="onCustomerChange"
                            @click:clear="onCustomerChange"
                            :search-input.sync="customerSearch"
                            :label="$t('salesReport.customer')"
                          >
                            <template v-slot:append-outer>
                              <v-icon color="primary" dense @click="customerDialog = true">
                                mdi-magnify
                              </v-icon>
                            </template>
                            <template v-slot:selection="data">
                              <span>{{ `${data.item.id}. ${data.item.name_1}` }}</span>
                            </template>
                            <template v-slot:item="data">
                              <div class="font-weight-medium" style="font-size: .8125rem;display: flex;align-item: center;">
                                {{ `${data.item.id}. ${data.item.name_1}` }}
                              </div>
                            </template>
                            <template v-slot:append-item>
                              <div v-show="hasNextPage" v-intersect="infiniteScroll" ref="load" class="loader text-center">
                                <v-progress-circular
                                  indeterminate
                                  color="primary"
                                />
                              </div>
                            </template>
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="6">
                          <v-autocomplete
                            hide-details="auto"
                            dense
                            v-model="selectedCustomerGroup"
                            :items="customerGroupValue"
                            item-text="description"
                            item-value="id"
                            clearable
                            flat
                            @input="buildUrl"
                            return-object
                            :label="$t('salesReport.group')"
                          />
                        </v-col>
                        <v-col cols="6">
                          <v-autocomplete
                            hide-details="auto"
                            v-model="selectedProductGroup"
                            :items="productGroupValue"
                            item-text="description"
                            item-value="id"
                            clearable
                            flat
                            @input="buildUrl"
                            return-object
                            :label="$t('salesReport.itemGroup')"
                          />
                        </v-col>
                        <v-col cols="6">
                          <v-autocomplete
                            autocomplete="new-password"
                            v-model="selectedEmployee"
                            cache-items
                            :items="employeeList.data"
                            item-text="name"
                            item-value="id"
                            flat
                            return-object
                            hide-no-data
                            hide-details="auto"
                            single-line
                            clearable
                            @change="onEmployeeChange"
                            @click:clear="onEmployeeChange"
                            :search-input.sync="employeeSearch"
                            :label="$t('salesReport.agent')"
                          >
                            <template v-slot:append-outer>
                              <v-icon class="mt-1" color="primary" dense  @click="employeeDialog = true">
                                mdi-magnify
                              </v-icon>
                            </template>
                            <template v-slot:selection="data">
                              <span>{{ `${data.item.id}. ${data.item.name}` }}</span>
                            </template>
                            <template v-slot:item="data">
                              <div class="font-weight-medium" style="font-size: .8125rem;display: flex;align-item: center;">
                                {{ `${data.item.id}. ${data.item.name}` }}
                              </div>
                            </template>
                            <template v-slot:append-item>
                              <div v-show="hasEmployeeNextPage" v-intersect="employeeInfiniteScroll" ref="load" class="loader text-center">
                                <v-progress-circular
                                  indeterminate
                                  color="primary"
                                />
                              </div>
                            </template>
                          </v-autocomplete>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" class="text-center mt-3">
                      <ExecuteAndRestCommon :valid="valid" @reset="$store.commit('salesReport/SET_SALESREPORT', [])" @paginate="paginate(false)" />
                    </v-col>
                  </v-row>
                </v-card-text>
                </v-form>
                <v-card-title class="py-1 primary--text">
                  <v-row dense no-gutters>
                    <v-col cols="6" class="d-flex">
                      <v-icon color="primary">mdi-view-list-outline</v-icon>
                      <h6 class="ms-2 align-self-center font-weight-regular">{{$t('salesReport.debitReport')}}</h6>
                    </v-col>
                    <v-col cols="2" class="font-weight-regular">
                      <v-switch
                        :disabled="isFocused"
                        hide-details="auto"
                        v-model="withPrice"
                        color="primary"
                        class="float-end mt-0"
                      >
                        <template v-slot:label>
                          <span :class="withPrice ? 'primary--text' : ''">{{$t('salesReport.withPrice')}}</span>
                        </template>
                      </v-switch>
                    </v-col>
                    <v-col cols="2" class="font-weight-regular pe-4">
                      <v-switch
                        :disabled="withPrice"
                        hide-details="auto"
                        v-model="isFocused"
                        color="primary"
                        class="float-end mt-0"
                      >
                        <template v-slot:label>
                          <span :class="isFocused ? 'primary--text' : ''">{{$t('salesReport.focused')}}</span>
                        </template>
                      </v-switch>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        ref="search"
                        :full-width="false"
                        :dense="true"
                        hide-details="auto"
                        v-model="search"
                        append-icon="mdi-magnify"
                        :label="$t('common.search')"
                        single-line
                      />
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-divider />
              </template>
              <template v-slot:no-data>
                <v-card-text class="text-caption text-center"> {{$t('common.noDataFound')}}</v-card-text>
              </template>
              <template v-slot:[`item.type`]="props">
                <span v-if="props.item.type">{{ $t(`salesReport.${props.item.type}`) }}</span>
                <span v-else></span>
              </template>
              <template v-slot:[`item.customer_name`]="props">
                <span :class="!props.item.type && !props.item.amountTotal ? 'font-weight-bold' : ''">{{ props.item.customer_name }}</span>
              </template>
              <template v-slot:[`item.invoice_id`]="props">
                <span :id="`row-${props.item.invoice_id}`">{{ props.item.invoice_id }}</span>
              </template>
              <template v-slot:[`item.invoice_date`]="props">
                <span>{{ props.item.invoice_date && (profileById.date_format ? formatDateDDMMYYYY(new Date(props.item.invoice_date).toISOString().substring(0,10)) :new Date(props.item.invoice_date).toISOString().substring(0,10))  }}</span>
              </template>
              <template v-slot:[`item.balance`]="props">
                <span v-if="accessRight.includes('financial')" style="direction:ltr" :class="!props.item.type ? 'font-weight-bold' : ''" class="d-inline-block">{{ numberWithCommas(props.item.balance) }}</span>
              </template>
              <template v-slot:[`item.amount`]="props">
                <span v-if="accessRight.includes('financial') && !props.item.type" style="direction:ltr" class="font-weight-bold d-inline-block">{{$t('salesReport.withPrice')}}:{{numberWithCommas(props.item.amount)}}</span>
                <span v-else-if="accessRight.includes('financial') && props.item.type" style="direction:ltr" class="d-inline-block">{{numberWithCommas(props.item.amount)}}</span>
              </template>
              <template v-slot:[`item.cumulitiveTotal`]="props">
                <span v-if="accessRight.includes('financial') && !props.item.type" style="direction:ltr" class="font-weight-bold d-inline-block">{{$t('salesReport.cumulativeAmount')}}:{{numberWithCommas(props.item.cumulitiveTotal)}}</span>
                <span v-else-if="accessRight.includes('financial') && props.item.type" style="direction:ltr" class="d-inline-block">{{numberWithCommas(props.item.cumulitiveTotal)}}</span>
              </template>
              <template v-slot:[`item.amountTotal`]="props">
                <span v-if="accessRight.includes('financial')" style="direction:ltr" class="d-inline-block">{{numberWithCommas(props.item.amountTotal)}}</span>
              </template>
              <template v-slot:[`footer.page-text`]>
                <v-row class="d-flex">
                  <v-col class="mx-auto mr-16" cols="4">
                    <v-container class="w-100">
                      <v-pagination :total-visible="5" v-model="page" :length="salesReportList.data ? pageCount : 0"></v-pagination>
                    </v-container>
                  </v-col>
                </v-row>
              </template>
            </v-data-table>
            <v-divider />
            <v-row>
              <v-col class="mx-auto" cols="3" v-if="!isFocused">
                <span class="ps-3 pt-2 text-caption">{{ $t('common.numberOfTotalRows') }} {{salesReportList && salesReportList.totalRows ? salesReportList.totalRows : 0}}</span>
              </v-col>
              <v-col class="mx-auto" cols="3" v-else>
                <span class="ps-3 pt-2 text-caption">{{ $t('common.numberOfTotalRows') }} {{salesReportList && salesReportList.isFocusedTotalRows ? salesReportList.isFocusedTotalRows : 0}}</span>
              </v-col>
              <v-col cols="5">
                <v-row dense v-if="accessRight.includes('financial')">
                  <v-col cols="10" class="text-end font-weight-bold" :class="locale === 'he' ? 'text-start' : 'text-end'">
                    <span class="ps-6 pt-2 text-body-2 font-weight-bold">
                      {{$t('salesReport.alloriginalAmountTotal')}}: {{salesReportList && salesReportList.alloriginalAmountTotal ? numberWithCommas(salesReportList.alloriginalAmountTotal) : 0}}
                    </span>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="4">
                <v-row dense v-if="accessRight.includes('financial')">
                  <v-col cols="10" class="text-end font-weight-bold" :class="locale === 'he' ? 'text-start' : 'text-end'">
                    <span class="ps-6 pt-2 text-body-2 font-weight-bold">
                      {{$t('salesReport.allCumulitiveTotal')}}: {{salesReportList && salesReportList.allCumulitiveTotal ? numberWithCommas(salesReportList.allCumulitiveTotal) : 0}}
                    </span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <EmployeeDialog @onEmployeeChange="onEmployeeChange" :dialog.sync="employeeDialog" />
      <CustomerDialog @onCustomerChange="onCustomerChange" :dialog.sync="customerDialog" />
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import {getAuxiliaryZtableValueByName} from '@/utils';
import ListSkeleton from '@/components/skeleton/ListSkeleton';
import HtmlToPdf from "@/components/generatePDF/HtmlToPdf";
import {formatDateDDMMYYYY, parseDateYYYYMMDD, todayDate} from '@/utils';
import CustomerDialog from "@/components/moduleTableDialog/Dialog/CustomerDialog";
import EmployeeDialog from "@/components/moduleTableDialog/Dialog/EmployeeDialog";
import exportFromJSON from 'export-from-json';
import ExcelAndResetFilterCommon from "@/components/ExcelAndResetFilterCommon";
import ExecuteAndRestCommon from '@/components/ExecuteAndRestCommon';
import { validationMixin } from '@/mixins/validationMixin';
import { intersectionMixin } from '@/mixins/intersectionMixin';
import { searchMixin } from '@/mixins/searchMixin';

export default {
  name: 'SalesReport',
  components: {
    ListSkeleton,
    HtmlToPdf,
    EmployeeDialog,
    CustomerDialog,
    ExcelAndResetFilterCommon,
    ExecuteAndRestCommon
  },
  mixins: [validationMixin, intersectionMixin, searchMixin],
  data() {
    return {
      page: Number(this.$route.query.page) || 1,
      limit: 10,
      sortByValue: null,
      sortDescValue: null,
      customerPage: 1,
      customerLimit: 10,
      employeePage: 1,
      employeeLimit: 10,
      limitData: this.limit,
      pagination: null,
      accessRights: [],
      employeeSearch: '',
      selectedCustomer: null,
      selectedCustomerGroup: null,
      selectedProductGroup: null,
      selectedEmployee: null,
      customerGroupValue: [],
      productGroupValue: [],
      search: "",
      listSkeleton: false,
      invoiceStartDateMenu: false,
      invoiceEndDateMenu: false,
      invoice_start_date: '1990-01-01',
      isFocused: false,
      withPrice: false,
      customerDialog: false,
      employeeDialog: false,
      model: {
        date_printed: "",
      },
      valid: false,
      custom: {id: 111111111111111, description: this.$t('invoice.empty')},
      numberOfRows: "",
      invoiceData: [],
      withPriceinvoiceData: [],
      isProgressCircular: false,
    }
  },
  async created() {
    const self = this;
    self.getNow();
  },
  computed: {
    formattedFromDate: {
      get() {
        return this.profileById.date_format ? formatDateDDMMYYYY(this.invoice_start_date) : parseDateYYYYMMDD(this.invoice_start_date);
      },
      set(val) {
        if (val.length == 10) {
          this.invoice_start_date = this.profileById.date_format ? this.parseDateYYYYMMDD(val) : formatDateDDMMYYYY(val);
        }
      }
    },
    formattedToDate: {
      get() {
        return this.profileById.date_format ? formatDateDDMMYYYY(this.model.date_printed) : parseDateYYYYMMDD(this.model.date_printed);
      },
      set(val) {
        if (val.length == 10) {
          this.model.date_printed = this.profileById.date_format ? this.parseDateYYYYMMDD(val) : formatDateDDMMYYYY(val);
        }
      }
    },
    params() {
      if(this.pagination) {
        return {
          ...this.pagination,
          customer_group_id: this.selectedCustomerGroup?.id,
          query: this.search,
          customer_id: this.selectedCustomer?.id,
          item_group_id: this.selectedProductGroup?.id,
          employee_id: this.selectedEmployee?.id,
          invoice_start_date: this.invoice_start_date,
        };
      } else {
        return {}
      }
    },
    payload() {
      return {
        customer_group: this.selectedCustomerGroup?.description,
        customer: this.selectedCustomer?.name_1,
        item_group: this.selectedProductGroup?.description,
        employee: this.selectedEmployee?.name,
        invoice_start_date: this.invoice_start_date,
        invoice_end_date: this.model.date_printed
      }
    },
    ...mapGetters({
      salesReportList: 'salesReport/salesReportList',
      customerList: 'customer/customerList',
      employeeList: 'employee/employeeList',
      locale: 'locale',
      accessRight: 'accessRight',
      profileById: 'profile/profileById',
      rowIndex: "salesReport/rowIndex",
    }),
    headers() {
      return [
        { text: this.$t('salesReport.type'), align: "start", value: "type", width: '125px', class: 'px-1', sortable: false },
        { text: this.$t('salesReport.customerNumber'), align: "start", value: "customer_id", width: '125px', class: 'px-1', sortable: false },
        { text: this.$t('salesReport.customerName'), value: "customer_name", width: '140px', class: 'px-2', sortable: false },
        { text: this.$t('salesReport.invoiceNumber'), value: "invoice_id", width: '112px', class: 'px-1', sortable: false },
        { text: this.$t('salesReport.invoiceDate'), value: "invoice_date", width: '112px', class: 'px-2', sortable: false },
        { text: this.$t('salesReport.originalAmount'), value: "amount", width: '138px', sortable: false },
        { text: this.$t('salesReport.cumulativeAmount'), value: "cumulitiveTotal", width: '156px', sortable: false },
      ]
    },
    focusedHeaders() {
      return [
        { text: this.$t('salesReport.customerNumber'), align: "start", value: "customer_id", width: '125px', class: 'px-1', sortable: false },
        { text: this.$t('salesReport.customerName'), value: "customer_name", width: '140px', class: 'px-2', sortable: false },
        { text: this.$t('salesReport.tel1'), value: "phone_1", width: '140px', class: 'px-2', sortable: false },
        { text: this.$t('salesReport.cel1'), value: "cellular_1", width: '140px', class: 'px-2', sortable: false },
        { text: this.$t('salesReport.balance'), value: "amountTotal", width: '95px', sortable: false },
      ]
    },
    empty() {
      return this.$t('invoice.empty')
    }
  },
  watch: {
    empty() {
      this.custom.description = this.empty
    },
    employeeSearch(val) {
      val ? this.$store.dispatch('employee/GetEmployee', {page: 1, limit: 10, where_like: val ? 'name|'+val : val, order_by: 'name|asc'}) : '';
    },
    customerLimit() {
      this.$store.dispatch('customer/GetCustomer', {page: 1, limit: this.customerLimit, order_by: 'name_1|asc'});
    },
    employeeLimit() {
      this.$store.dispatch('employee/GetEmployee', {page: 1, limit: this.employeeLimit, order_by: 'name|asc'});
    },
    pagination: {
      handler(newVal) {
          if (this.pagination) {
          this.limit = newVal.itemsPerPage ? newVal.itemsPerPage : 10
          }
      },
      deep: true
    },
    salesReportList: {
      handler(newVal) {
        this.invoiceData = []
        this.withPriceinvoiceData = []
        if (newVal.data && newVal.data.length > 0) {
          newVal.data.forEach((item) => {
            item.invoiceData.forEach((dd) => {
              this.invoiceData.push(dd)
              this.withPriceinvoiceData.push(dd)
            })
            this.invoiceData.push(
              {
                customer_name: item.customer_name + ' (' + item.customer_id +')',
                amount: item.amountTotal,
                cumulitiveTotal: item.cumulitiveTotal,
              }
            )
          })
        }
      },
      deep: true
    }
  },
  destroyed() {
    this.$store.commit('salesReport/SET_SALESREPORT', [])
  },
  async mounted() {
    this.$store.commit("salesReport/SET_SALESREPORT_QUERY", this.$route.query);
    this.$store.dispatch('customer/GetCustomer', {page: this.customerPage, limit: this.customerLimit, order_by: 'name_1|asc'});
    this.$store.dispatch('employee/GetEmployee', {page: this.employeePage, limit: this.employeeLimit, order_by: 'name|asc'});
    getAuxiliaryZtableValueByName(2).then((tableCustomerValue) => {
      if(Object.keys(tableCustomerValue).length > 0) {
        this.customerGroupValue = tableCustomerValue.ztables.sort((a, b) => (a.description.toLocaleLowerCase() > b.description.toLocaleLowerCase()) ? 1 : -1);
        this.customerGroupValue = [this.custom, ...this.customerGroupValue]
      }
    });
    getAuxiliaryZtableValueByName(3).then((tableProductValue) => {
      if (Object.keys(tableProductValue).length > 0) {
        this.productGroupValue = tableProductValue.ztables.sort((a, b) => a.description.toLocaleLowerCase() > b.description.toLocaleLowerCase() ? 1 : -1);
        this.productGroupValue = [this.custom, ...this.productGroupValue]
      }
    });
    this.oneYearAgoDate();
    // let rowIndex = this.$store.state.salesReport.rowIndex;
    // if (rowIndex && this.salesReportList?.data?.length > 0) {
    //   document.getElementById(`row-${rowIndex}`).scrollIntoView({ block: "center" })
    // }
    this.listSkeleton = false;
  },
  methods: {
    parseDateYYYYMMDD:parseDateYYYYMMDD,
    formatDateDDMMYYYY: formatDateDDMMYYYY,
    todayDate: todayDate,
    newPagination(val) {
      this.pagination = val
      this.numberOfRows = val.itemsLength
    },
    oneYearAgoDate() {
      const currentDate = new Date();
      const oneYearAgoDate = new Date(currentDate);
      oneYearAgoDate.setFullYear(currentDate.getFullYear() - 1);
      this.invoice_start_date = `${oneYearAgoDate.getFullYear()}-${(oneYearAgoDate.getMonth() + 1).toString().padStart(2, '0')}-${oneYearAgoDate.getDate().toString().padStart(2, '0')}`;
    },
    onEmployeeChange(item) {
      if (item && typeof item === "object" && Object.keys(item).length > 0) {
        this.selectedEmployee = {id: item.id, name: item?.name};
        this.buildUrl()
      } else {
        this.selectedEmployee = null;
      }
    },
    async onCustomerChange(customer) {
      if (customer && typeof customer === "object" && Object.keys(customer).length > 0) {
        this.selectedCustomer = {id: customer.id, name_1: customer?.name_1};
        this.buildUrl()
      } else {
        this.selectedCustomer = null
      }
    },
    getNow() {
      const today = new Date();
      let date = today.getDate();
      if (date < 10) {
        date = "0" + date;
      }
      let month = today.getMonth() + 1;
      if (month < 10) {
        month = "0" + month;
      }
      const newDate = today.getFullYear() + "-" + month + "-" + date;
      this.model.date_printed = newDate;
    },
    doubleClick(event, {item}) {
      if (!this.isFocused && item.invoice_id) {
        const path = 'invoice/invoiceAction/' + item.invoice_id
        this.accessRight.includes('edit') || this.accessRight.includes('show') ? window.open(path, '_blank') : '';
        this.$store.commit('salesReport/SET_ROWINDEX', item.invoice_id);
      }
    },
    downloadExcelFile() {
      let self = this;
      if (this.isFocused === false) {
        let res = [];
        self.salesReportList.data.forEach((el) => {
        el.invoiceData?.map((o) =>
          res.push(Object.fromEntries(
            self.headers
              .map((k) => [k.text, o[k.value]])
            ))
          );
        });
        if (this.locale === 'he') {
          res.forEach((el)=> {
          for (let x in el) {
            if (el[x] == 'Invoice') {
              el[x] = 'חשבונית'
            }}
          })
        }
        if (this.locale === 'he') {
          res.forEach((el)=> {
          for (let x in el) {
            if (el[x] == 'CreditInvoice') {
              el[x] = 'חשבונית זיכוי'
            }}
          })
        }
        exportFromJSON({data: res, fileName: "salesReport", exportType: exportFromJSON.types.xls})
      } 
      else if (this.isFocused === true) {
        let res = [];
        self.salesReportList.data.forEach((el, index) => {
          let uniqueIds = [];
          el.invoiceData.forEach((element) => {
            let isDuplicate = uniqueIds.includes(element.customer_id);
            if (!isDuplicate) {
              uniqueIds.push(element.customer_id);
              res.push(
                Object.fromEntries(
                  self.focusedHeaders.map((k) => [k.text, element[k.value]])
                )
              );
            }
          });
          res[index][this.$t('salesReport.balance')] = el.amountTotal;
        });
        exportFromJSON({data: res, fileName: "salesReport", exportType: exportFromJSON.types.xls})
      }
    },
    resetFilters() {
      this.sortByValue = null;
      this.sortDescValue = null;
      this.invoice_start_date = '1990-01-01';
      this.search = null;
      this.selectedProductGroup = null;
      this.selectedCustomer = null;
      this.selectedCustomerGroup = null;
      this.selectedEmployee = null;
      this.$store.commit("salesReport/SET_SALESREPORT_QUERY", {});
      this.buildUrl();
    },
    numberWithCommas(x) {
      return x ? parseFloat(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : (0).toFixed(2);
    },
    buildUrl(val) {
      let obj = {};
      if (typeof val === 'string' || typeof val === 'object') {
        // this.page = 1;
        // obj.page = 1;
        // this.params.page = 1;
      }
      obj.custId = this.selectedCustomer?.id;
      obj.empId = this.selectedEmployee?.id;
      obj.invoiceStartDate = this.invoice_start_date;
      obj.order_by = this.sortByValue;
      obj.sortDec = this.sortDescValue;
      obj.productGroupId = this.selectedProductGroup?.id;
      obj.customerGroupId = this.selectedCustomerGroup?.id;
      obj = Object.entries(obj).reduce((acc, [key, val]) => {
        if (key === 'custId' && val === null) return acc;
        else if (key === "customerGroupId" && val === null) return acc;
        else if (key === "invoiceStartDate" && val === null) return acc;
        else if (key === "invoiceEndDate" && val === null) return acc;
        else if (key === "empId" && val === null) return acc;
        else if (key === "order_by" && val === null) return acc;
        else if (key === "sortDec" && val === null) return acc;
        return { ...acc, [key]: val };
      }, {});
      this.$store.commit("salesReport/SET_SALESREPORT_QUERY", obj);
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.customerLimit += 10;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    async employeeInfiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.employeeLimit += 10;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    async paginate() {
      const {
        customer_group_id,
        customer_id,
        item_group_id,
        employee_id,
        invoice_start_date,
      } = this.params;

      const payloadData = {
        'from_invoices.invoice_date': invoice_start_date,
        'to_invoices.invoice_date': this.model.date_printed,
        where: customer_id ? "invoices.customer_id|" + customer_id : undefined,
        whereAnd: customer_group_id ? "group_id|" + customer_group_id : undefined,
        where_and_item: item_group_id ? "items.group_id|" + item_group_id : undefined,
        where_and_employee: employee_id ? "invoices.employee_id|" + employee_id : undefined,
        empty: 'group_id|' + 'NULL',
        empty_1: 'items.group_id|' + 'NULL'
      }

      if (customer_group_id && customer_group_id === 111111111111111) {
        payloadData.empty, delete payloadData.whereAnd
      } else {
        payloadData.whereAnd, delete payloadData.empty
      } 

      if (item_group_id && item_group_id === 111111111111111) {
        payloadData.empty_1, delete payloadData.where_and_item
      } else {
        payloadData.where_and_item, delete payloadData.empty_1
      }

      this.isProgressCircular = true;
      // end make params for where_and
      await this.$store.dispatch("salesReport/GetMainSalesReport", payloadData).then(() => {
        this.isProgressCircular = false;
      });
    }
  }
}
</script>

<style scoped>
::v-deep .v-pagination__item {
  font-size: 12px;
}
::v-deep .custom-table ::-webkit-scrollbar {
  width: 12px;
}
</style>